<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-data-table
    dense
    :headers="headers"
    :items="itemspatients"
    :server-items-length="totalpatient"
    :options.sync="options"
    item-key="index"
    class="elevation-1"
    :loading="loading"
    @click:row="goToPatient"
  >
    <template v-slot:[`item.patientDoctor`]="{ item }">
      <template v-if="item.patientDoctor">
        <v-btn
          rounded
          small
          :color="getColor(getName(item.patientDoctor.fullname))"
          class="text-capitalize btn_name"
        >
          {{ getName(item.patientDoctor.fullname) }}</v-btn
        >
      </template>
    </template>
    <template v-slot:[`item.createAt`]="{ item }">
      {{ getDate(item.createAt) }}
    </template>
    <template v-slot:[`item.balance`]="{ item }">
      {{ item.id | currency }}
    </template>
    <template v-slot:[`item.fullname`]="{ item }">
      <span class="text-capitalize">{{ item.fullname }}</span>
    </template>
    <template v-slot:[`item.labs`]="{ item }">
      <template v-if="item.labsCkeck.a1c">
        <v-btn
          rounded
          small
          :color="getColor(getName('a1c'))"
          class="text-capitalize btn_name"
        >
          a1c</v-btn
        >
      </template>
      <template v-if="item.labsCkeck.chem">
        <v-btn
          rounded
          small
          :color="getColor(getName('chem'))"
          class="text-capitalize btn_name"
        >
          Chem</v-btn
        >
      </template>
      <template v-if="item.labsCkeck.ekg">
        <v-btn
          rounded
          small
          :color="getColor(getName('ekg'))"
          class="text-capitalize btn_name"
        >
          ekg</v-btn
        >
      </template>
      <template v-if="item.labsCkeck.hcg">
        <v-btn
          rounded
          small
          :color="getColor(getName('hcg'))"
          class="text-capitalize btn_name"
        >
          hcg</v-btn
        >
      </template>
      <template v-if="item.labsCkeck.hiv">
        <v-btn
          rounded
          small
          :color="getColor(getName('hiv'))"
          class="text-capitalize btn_name"
        >
          hiv</v-btn
        >
      </template>
      <template v-if="item.labsCkeck.man_us">
        <v-btn
          rounded
          small
          :color="getColor(getName('man'))"
          class="text-capitalize btn_name"
        >
          MAM/US</v-btn
        >
      </template>
      <template v-if="item.labsCkeck.mc">
        <v-btn
          rounded
          small
          :color="getColor(getName('mc'))"
          class="text-capitalize btn_name"
        >
          mc</v-btn
        >
      </template>
      <template v-if="item.labsCkeck.pt">
        <v-btn
          rounded
          small
          :color="getColor(getName('pt'))"
          class="text-capitalize btn_name"
        >
          pt</v-btn
        >
      </template>
      <template v-if="item.labsCkeck.pt_ptt_inr">
        <v-btn
          rounded
          small
          :color="getColor(getName('pt_ptt_inr'))"
          class="text-capitalize btn_name"
        >
          PT/PTT/INR</v-btn
        >
      </template>
      <template v-if="item.labsCkeck.ua">
        <v-btn
          rounded
          small
          :color="getColor(getName('ua'))"
          class="text-capitalize btn_name"
        >
          ua</v-btn
        >
      </template>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-btn
        small
        rounded
        :color="getColorStatus(item.status)"
        class="text-capitalize btn_name"
        >{{ item.status }}</v-btn
      >
    </template>
  </v-data-table>
</template>
<script>
import { utilMixin } from "@/utils/mixins";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "pps-sales",
  mixins: [utilMixin],
  data: () => ({
    options: {},
    itemspatients: [],
  }),
  watch: {
    options: {
      handler() {
        this.getPatients();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["getCoordinatorN", "getpatientN"]),
    ...mapState("crmMedicFormModule", ["patients", "totalpatient", "loading"]),
    headers() {
      return [
        {
          text: "A " + this.getpatientN,
          color: "pink",
          sortable: false,
          width: 250,
          value: "fullname",
        },
        { text: "DOS", value: "createAt" },
        { text: this.getCoordinatorN, width: 40, value: "patientDoctor" },
        { text: "Pre-Op Status", value: "status" },
        { text: "Balance", value: "balance" },
        { text: "Procedure", value: "iron" },
        { text: "PRE OP Notes", value: "iron" },
        { text: "Labs", value: "labs" },
      ];
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListPatients"]),
    getName(fullname) {
      const name = fullname.split(" ")[0];
      return name;
    },
    goToPatient(item) {
      this.$router.push("/patients/details/" + item.uuid);
    },

    async getPatients() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
      const body = { limit: itmper, offset: (page - 1) * itmper };
      await this.actListPatients(body);
      this.itemspatients = this.patients;

      if (sortBy.length === 1 && sortDesc.length === 1) {
        this.itemspatients = this.itemspatients.sort((a, b) => {
          const sortA = a[sortBy[0]];
          const sortB = b[sortBy[0]];

          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.btn_name {
  height: 20px !important;
  margin: 0 1px;
}
</style>
