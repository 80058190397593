var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.itemspatients,"server-items-length":_vm.totalpatient,"options":_vm.options,"item-key":"index","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToPatient},scopedSlots:_vm._u([{key:"item.patientDoctor",fn:function(ref){
var item = ref.item;
return [(item.patientDoctor)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName(item.patientDoctor.fullname))}},[_vm._v(" "+_vm._s(_vm.getName(item.patientDoctor.fullname)))])]:_vm._e()]}},{key:"item.createAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createAt))+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.id))+" ")]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.fullname))])]}},{key:"item.labs",fn:function(ref){
var item = ref.item;
return [(item.labsCkeck.a1c)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('a1c'))}},[_vm._v(" a1c")])]:_vm._e(),(item.labsCkeck.chem)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('chem'))}},[_vm._v(" Chem")])]:_vm._e(),(item.labsCkeck.ekg)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('ekg'))}},[_vm._v(" ekg")])]:_vm._e(),(item.labsCkeck.hcg)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('hcg'))}},[_vm._v(" hcg")])]:_vm._e(),(item.labsCkeck.hiv)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('hiv'))}},[_vm._v(" hiv")])]:_vm._e(),(item.labsCkeck.man_us)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('man'))}},[_vm._v(" MAM/US")])]:_vm._e(),(item.labsCkeck.mc)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('mc'))}},[_vm._v(" mc")])]:_vm._e(),(item.labsCkeck.pt)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('pt'))}},[_vm._v(" pt")])]:_vm._e(),(item.labsCkeck.pt_ptt_inr)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('pt_ptt_inr'))}},[_vm._v(" PT/PTT/INR")])]:_vm._e(),(item.labsCkeck.ua)?[_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"rounded":"","small":"","color":_vm.getColor(_vm.getName('ua'))}},[_vm._v(" ua")])]:_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize btn_name",attrs:{"small":"","rounded":"","color":_vm.getColorStatus(item.status)}},[_vm._v(_vm._s(item.status))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }